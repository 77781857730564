<template>
    <div>
        <h2>รายงานยอดขายสินค้า แยกตามใบกำกับ</h2>

        <v-card class="mt-4 px-2 px-sm-6 pt-6"> 
          <v-container>            
            <v-row>
              <v-col class="d-flex align-sm-center flex-column flex-sm-row">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ml-0 ml-sm-auto"
                      >
                        <v-icon class="mr-2">{{ icons.mdiCalendar }}</v-icon> {{ displayDate }}
                      </v-btn>
                  </template>
                  <v-list>
                      <v-list-item
                      v-for="(item, index) in dateList"
                      :key="index"
                      @click="setDate(item.id)"
                      >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item                    
                      @click="callCalendarModal"
                      >
                          <v-list-item-title>กำหนดเอง</v-list-item-title>
                      </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>           
            <v-row>
              <v-col>
                <v-radio-group
                  v-model="reportOption"
                  row
                >
                  <v-radio
                    label="ดูยอดของทั้งออนไลน์และ POS"
                    value="both"
                  ></v-radio>
                  <v-radio
                    label="ดูเฉพาะยอดออนไลน์"
                    value="online"
                  ></v-radio>
                  <v-radio
                    label="ดูเฉพาะยอด POS"
                    value="pos"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-radio-group
                  v-model="receiptOption"
                  row
                >
                  <v-radio
                    label="เฉพาะใบกำกับอย่างย่อ (ABO/ABB)"
                    value="abb"
                  ></v-radio>
                  <v-radio
                    label="เฉพาะใบกำกับอย่างเต็ม (TRD)"
                    value="trd"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="saleChannels"             
                  label="ช่องทางการขายออนไลน์ที่ต้องการดู"
                  placeholder="เว้นว่างถ้าต้องการดูทุกช่องทางการขายออนไลน์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/channelList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="saleChannelAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                  :disabled="reportOption == 'pos'"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="posDevices"             
                  label="เครื่อง POS ที่ต้องการดู"
                  placeholder="เว้นว่างถ้าต้องการดูทุกเครื่อง POS"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/deviceList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="posDeviceAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                  :disabled="reportOption == 'online'"
                ></delay-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="brands"             
                  label="แบรนด์"
                  placeholder="เว้นว่างถ้าต้องการดูทุกแบรนด์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/brandList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="brandAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="categories"             
                  label="หมวดหมู่"
                  placeholder="เว้นว่างถ้าต้องการดูทุกหมวดหมู่"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/categoryList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="categoryAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="tags"             
                  label="แท็ก"
                  placeholder="เว้นว่างถ้าต้องการดูทุกแท็ก"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/tagList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="tagAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="products"
                  outlined
                  label="ชื่อสินค้า"
                  placeholder="เว้นว่างถ้าต้องการดูทุกสินค้า"                                    
                  hide-details="auto"          
                  dense
              ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <v-btn 
                @click="generateReport"
                class="ml-auto"
                color="primary"
                :disabled="!dates || !type"
                >ดูรายงาน</v-btn>
              </v-col>
            </v-row>
          </v-container>                        
        </v-card>

        <v-card class="mt-4">
          <div class="d-flex pa-4">            
            <v-btn  
            class="ml-2"   
            @click="exportExcel"            
            ><v-icon class="mr-2">{{ icons.mdiExportVariant }}</v-icon>ส่งออก</v-btn>            
          </div>
          <LocalDatatable
          :headers="localDatatable.headers"
          :items="tableData"    
          ref="datatable"      
          >
            <template          
              v-slot:item.image="{ item }"
            >   
              <product-image :image="item.image ? item.image : null" />                           
            </template>

            <template          
              v-slot:item.datetime="{ item }"
            >   
              {{ item.datetime.getDate() + '/' + (item.datetime.getMonth() + 1) + '/' + item.datetime.getFullYear() + ' (' + dateArray[item.datetime.getDay()] + ')' }}
            </template>

            <template          
              v-slot:item.before_discount="{ item }"
            >   
              {{ item.before_discount | formatNumber }}
            </template>

            <template          
              v-slot:item.discount="{ item }"
            >   
              {{ item.discount | formatNumber }}
            </template>

            <template          
              v-slot:item.sales="{ item }"
            >   
              {{ item.sales | formatNumber }}
            </template>
          </LocalDatatable>
          <!--
          <v-data-table
          :headers="localDatatable.headers"
          :items="tableData"
          :items-per-page="localDatatable.itemsPerPage"
          >
            <template          
              v-slot:item.datetime="{ item }"
            >   
              {{ item.datetime.getDate() + '/' + (item.datetime.getMonth() + 1) + '/' + item.datetime.getFullYear() + ' (' + dateArray[item.datetime.getDay()] + ')' }}
            </template>
          </v-data-table>
          -->
        </v-card>

        <v-dialog
          ref="dialog"
          v-model="calendarModal"        
          :return-value.sync="dates"  
          persistent
          width="290px"
        >
          <v-date-picker
            v-model="dates"
            scrollable
            range
            :max="maxDate"
            :min="minDate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              @click="calendarModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
    </div>
</template>
<script>
import LocalDatatable from '@/components/LocalDatatable.vue'
import saleschart from '@/components/charts/saleschart.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import { dateArray } from '@/helpers/dateTimeConverter'
import { mdiCalendar, mdiExportVariant } from '@mdi/js'
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      type: 'products',
      dateArray: dateArray,
      minDate: null,
      maxDate: null,
      loaded: false,
      dates: null,
      startDate: null,
      endDate: null,
      menu: false,
      calendarModal: false,
      reportOption: 'both',
      receiptOption: 'abb',
      saleChannels: null,
      posDevices: null,
      brands: null,
      categories: null,
      tags: null,
      products: null,
      chartData: {
        labels: [],
        datasets: [],
        hoverBackgroundColor: '#d84c52',
      },
      tableData: [],
      icons: {
        mdiCalendar,
        mdiExportVariant,
      },
      dateList: [
        {
          title: 'วันนี้',
          id: 'today',
        },
        {
          title: 'เมื่อวาน',
          id: 'yesterday',
        },
        {
          title: '7 วันที่แล้ว',
          id: 'last7',
        },
        {
          title: '30 วันที่แล้ว',
          id: 'last30',
        },
        {
          title: 'เดือนนี้',
          id: 'month',
        },
        {
          title: 'เดือนที่แล้ว',
          id: 'lastmonth',
        },
      ],
      selectedDateList: null,
      localDatatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        search: null,
        headers: [
          {
            text: 'วันที่',
            value: 'date',
            width: '150',
          },
          {
            text: 'ช่องทาง',
            value: 'channelName',
          },
          {
            text: 'สินค้า',
            value: 'name',
          },
          {
            text: 'sku',
            value: 'sku',
          },
          {
            text: 'บาร์โคด',
            value: 'barCode',
          },
          {
            text: 'จำนวน',
            value: 'quantity',
          },
          {
            text: 'ยอดขายก่อนส่วนลด',
            value: 'before_discount',
          },
          {
            text: 'ส่วนลด',
            value: 'discount',
          },
          {
            text: 'ยอดขาย',
            value: 'sales',
          },
        ],
      },
    }
  },
  watch: {
    type(value, oldValue) {
      if (!!value) return

      if (!value && oldValue == 'products') {
        this.$nextTick().then(() => {
          this.type = 'products'
        })
      } else {
        this.type = 'products'
      }
    },
  },
  created() {
    const today = new Date()
    const minDate = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate())

    this.maxDate = this.dateToDBFormat(today)
    this.minDate = this.dateToDBFormat(minDate)
  },
  methods: {
    setDate(itemId) {
      let today = new Date()

      if (itemId == 'today') {
        this.dates = this.dateToDBFormat(today)
      } else if (itemId == 'yesterday') {
        const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        // this.dates = this.dateToDBFormat(yesterday)
        this.dates = [this.dateToDBFormat(yesterday), this.dateToDBFormat(yesterday)]
      } else if (itemId == 'last7') {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

        this.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(today)]
      } else if (itemId == 'last30') {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)

        this.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(today)]
      } else if (itemId == 'month') {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)

        this.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(today)]
      } else if (itemId == 'lastmonth') {
        const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const lastDay = new Date(today.getFullYear(), today.getMonth(), 0)

        this.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(lastDay)]
      }
    },
    dateToDBFormat(date) {
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0')
      )
    },
    dateDBFormatToThaiStandardFormat(dateString) {
      const [year, month, date] = dateString.split('-')

      return date + '/' + month + '/' + year
    },
    callCalendarModal() {
      this.dates = null
      this.calendarModal = true
    },
    async generateReport() {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      const startDate = Array.isArray(this.dates) ? this.dates[0] : this.date
      const endDate = Array.isArray(this.dates) ? this.dates[1] : this.date

      const filter = {}

      if (!!startDate) filter.start = startDate

      if (!!endDate) filter.end = endDate

      if (!!this.brands) filter.brands = this.brands.map(item => item.id)

      if (!!this.categories) filter.categories = this.categories.map(item => item.id)

      if (!!this.tags) filter.tags = this.tags.map(item => item.id)

      if (this.reportOption == 'both' || this.reportOption == 'online') {
        if (!!this.saleChannels) filter.channels = this.saleChannels.map(item => item.id)
      } else {
        filter.channels = ['']
      }

      if (this.reportOption == 'both' || this.reportOption == 'pos') {
        if (!!this.posDevices) filter.devices = this.posDevices.map(item => item.id)
      } else {
        filter.devices = ['']
      }

      filter.document = this.receiptOption

      if (!!this.products) {
        filter.products = this.products
      }

      filter.type = 'products'

      try {
        const { productsData } = await asyncGet('report/salesbydocument', filter)

        this.tableData = []
        const that = this
        for (const [date, valueByDate] of Object.entries(productsData)) {
          // Date Loop

          const toBeLoop = !!valueByDate['\0*\0items'] ? valueByDate['\0*\0items'] : valueByDate

          for (const [channelName, valueByChannel] of Object.entries(toBeLoop)) {
            // Channel Loop

            valueByChannel.forEach(element => {
              const { image, name, sku, barCode, quantity, sales, before_discount, discount } = element

              that.tableData.push({
                date: date,
                channelName: channelName,
                name: name,
                sku: sku,
                barCode: barCode,
                quantity: quantity,
                sales: sales,
                before_discount: before_discount,
                discount: discount,
              })
            })
          }
        }
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
      this.loaded = true
    },
    exportExcel() {
      //const headers = this.localDatatable.headers.map(header => header.value)
      console.log(this.$refs.datatable)
      const headers = this.$refs.datatable.selectedHeaders.map(header => header.value)
      const data = this.tableData.map(item => {
        return headers.map(header => {
          if (!!item[header]) return item[header]

          if (item[header] == 0) return 0

          return ''
        })
      })

      const arrayData = [headers, ...data]

      const dataWS = XLSX.utils.aoa_to_sheet(arrayData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'export_sales_by_product.xlsx')
    },
  },
  computed: {
    displayDate() {
      if (!this.dates) return 'โปรดเลือกวันที่'

      if (Array.isArray(this.dates)) {
        return this.dates.map(dateString => this.dateDBFormatToThaiStandardFormat(dateString)).join(' - ')
      }

      return this.dateDBFormatToThaiStandardFormat(this.dates)
    },
  },
  components: {
    saleschart,
    LocalDatatable,
  },
}
</script>